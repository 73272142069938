.plantin { font-family: plantin, serif; }

html {
  font-size: 21px;
}
body {
  line-height: 1.45;
  color: #17241D;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(30, 33, 35);
    color: #F0F3F3;
  }
}
blockquote {
  position: relative;
  border-left: 0;
  padding: 1.5rem;
  border-radius: .5rem;
  border: 2px solid #BDD3C0;
}
@media(min-width: 60em) {
  blockquote {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}
blockquote:before {
  content: '\2767';
  font-size: 3rem;
  position: absolute;
  bottom: -2.25rem;
  right: -1rem;
  font-style: normal;
  color: #BDD3C0;
}
hr {
  border-top: 2px solid rgba(#2A594E, 0.3);
}

.sage-dark { color: #17241D; }
.sage-mid-dark { color: #2A594E; }
.sage { color: #2A594E; }
.sage-light { color: #F0F3F3; }
.bg-sage-light { background-color: #F0F3F3; }
.bg-sage { background-color: #2A594E; }

.b--sage-light { border-color: #F0F3F3; }

.bg-sage-mid-dark { background-color: #2A594E; }

@media (prefers-color-scheme: dark) {
  .d-bg-sage-dark { background-color: #17241D; }
  .d-bg-white-10 { background-color: rgba(255, 255, 255, 0.1); }
  .d-bg-transparent { background-color: transparent; }
  .d-sage-light { color: #7F9B82; }
  .d-white-80 { color: rgba(255, 255, 255, 0.8); }
}
